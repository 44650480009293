import type { ParsedUrlQuery } from 'querystring';
import cuid from 'cuid';

const ID_KEY = 'peloton-feature-toggle-id';

export type Attributes = {
  locale: string;
  isEcommTester: boolean;
  email?: string;
  queryParams?: ParsedUrlQuery;
};

export const getId = (storage = window.localStorage): string => {
  try {
    const id = storage.getItem(ID_KEY);
    if (id === null) {
      const newId = cuid();
      storage.setItem(ID_KEY, JSON.stringify(newId));
      return newId;
    }
    return JSON.parse(id);
  } catch (e) {
    // This should never happen
    console.error(e);
    console.error(`Error getting ${ID_KEY} from local storage`);
    return '';
  }
};
