import { useRouter } from 'next/router';
import React from 'react';
import { DraftModeContext } from '@content/client/DraftModeContext';

export const NextDraftModeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isPreview } = useRouter();

  return (
    <DraftModeContext.Provider value={isPreview}>{children}</DraftModeContext.Provider>
  );
};
