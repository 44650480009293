import type { Client } from '@optimizely/optimizely-sdk';

type Variables = ReturnType<Client['getAllFeatureVariables']>;
type MaybeDateString = string | null;

const START_DATE_VARIABLE = 'startDate';
const END_DATE_VARIABLE = 'endDate';

const toParsedDate = (dateVariable: unknown) => {
  const dateString = dateVariable as MaybeDateString;
  if (!dateString) {
    return null;
  }

  const parsedDate = new Date(dateString);
  // .valueOf will return NaN if the date string is invalid
  if (isNaN(parsedDate.valueOf())) {
    throw new Error(`Invalid date variable string: ${dateString}`);
  }

  return parsedDate;
};

const isDateTriggerFeatureActive = (variables: Variables, nowOverride?: Date) => {
  if (!variables) {
    return true;
  }

  const now = nowOverride || new Date();

  try {
    const startDate = toParsedDate(variables[START_DATE_VARIABLE]);
    const endDate = toParsedDate(variables[END_DATE_VARIABLE]);

    if (startDate && startDate > now) {
      return false;
    }

    if (endDate && endDate < now) {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }

  return true;
};

export default isDateTriggerFeatureActive;
