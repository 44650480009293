import {
  accessibility,
  bike,
  bikeAccessories,
  bikePlus,
  bikes,
  instructors,
  instructorsBike,
  instructorsTread,
  membershipTerms,
  privacy,
  shopBike,
  shopBikePlus,
  shopTread,
  shopTreadPlus,
  tos,
  tread,
  treadAccessories,
  treadSpecs,
  digitalCheckout30Day,
  app,
  membership,
  cfuBikeSignup,
  cfuBikesSignup,
  cfuBikePlusSignup,
  shopGuide,
} from '@peloton/links/wwwPaths';
import { bikesClasses, bikesCompare } from '@peloton/links/wwwPrivatePaths';
import { treadsSpecs } from '@peloton/links/wwwPrivateTreadPaths';
import type { AvailabilityState } from './models';
import {
  ProductStates,
  isAvailableForMarketingPages,
  isAvailableForShopPages,
} from './models';
import { Product } from './models/product';

type GetProductAvailability = (y: Product) => AvailabilityState;

const anyAvailableProduct = (getProductAvailability: GetProductAvailability): boolean =>
  Object.values(Product)
    .map(getProductAvailability)
    .map(isAvailableForMarketingPages)
    .reduce((x, y) => x || y, false);

type ProductStateUrlMap = Record<string, (x: GetProductAvailability) => boolean>;

const productStateUrlMap: ProductStateUrlMap = {
  [cfuBikeSignup]: getProductAvailability => {
    return (
      getProductAvailability(Product.Bike).state ===
      ProductStates.UnavailableMarketingLeadCapture
    );
  },
  [cfuBikePlusSignup]: getProductAvailability => {
    return (
      getProductAvailability(Product.Bike).state ===
        ProductStates.UnavailableMarketingLeadCapture ||
      getProductAvailability(Product.BikePlus).state ===
        ProductStates.UnavailableMarketingLeadCapture
    );
  },
  [cfuBikesSignup]: getProductAvailability => {
    return (
      getProductAvailability(Product.Bike).state ===
        ProductStates.UnavailableMarketingLeadCapture ||
      getProductAvailability(Product.BikePlus).state ===
        ProductStates.UnavailableMarketingLeadCapture
    );
  },
  [bikes]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [bikesCompare]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [bikesClasses]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) ||
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)) ||
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [bike]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)),

  [shopBike]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Bike)),

  [bikePlus]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [shopBikePlus]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.BikePlus)),

  [bikeAccessories]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Bike)),

  [tread]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)),

  [shopTread]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Tread)),

  [shopTreadPlus]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.TreadPlus)),

  [treadAccessories]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Tread)),

  [treadSpecs]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)),

  [treadsSpecs]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.TreadPlus)),

  [instructors]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [instructorsBike]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)) &&
    (isAvailableForMarketingPages(getProductAvailability(Product.Bike)) ||
      isAvailableForMarketingPages(getProductAvailability(Product.BikePlus))),

  [instructorsTread]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)) &&
    (isAvailableForMarketingPages(getProductAvailability(Product.Tread)) ||
      isAvailableForMarketingPages(getProductAvailability(Product.TreadPlus))),

  [digitalCheckout30Day]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [app]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [membership]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [privacy]: anyAvailableProduct,
  [tos]: anyAvailableProduct,
  [membershipTerms]: anyAvailableProduct,
  [accessibility]: anyAvailableProduct,
  [shopGuide]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.RainforestCafe)),
};

export default productStateUrlMap;
